//jQuery(document).foundation(); 
var	isMobile = (/iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i.test(navigator.userAgent.toLowerCase())),
	isTablet = (/ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(navigator.userAgent.toLowerCase())),
    loadingMore = false,
	scrollPrev, elFilter, elFilterPos,
	currentMapCenter,
	canCloseSearchAgence = true,
		ajaxurl,
		currentDevice = false,
		autoreverse,
		elToAnimate,
		$window = $(window),


    site = {

	checkSafari : function() {
		if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
			document.getElementsByTagName('body')[0].classList.add('is-safari');
		}
	},

	init : function() {

		site.checkSafari();

        site.nav.init();
    //HANDLE RESIZE
        $(window).resize ( site.onResize );
        site.onResize();
    //NOW LOADED
        Pace.on('done', function() {

            //NAV & share
                if ( $('#social-sharing').length ) site.share.init();
			//FRONTPAGE
                if ( $('.home').length ) site.hp.init();
			//ACTU
                if ( $('.page--actu').length ) site.actu.init();
			//REALISATIONS
                if ( $('.page--realisation-listing').length ) site.realisation.init();
			//LISTING EMPLOI
				if ( $('.page--emploi-list').length ) site.emploi.init();
            //MAP
                //if ( $('.acf-map').length ) site.map.init();
            //SLIDER
                if ( $('.slider').length ) site.slider.init();
            // BLOC SLIDER
				if ( $('.bloc--slider').length ) site.hp.init();
            //SHARING
                if ( $('.sharing').length ) site.share.init();
            //ANIMATE
                site.animate.init(false);
                $('.container').addClass('loaded');
			//GO UP
				$('#go-up').click(function(){
					$('html, body').animate( { scrollTop: 0 }, 333 );
				});
			//GO FORM
				$('#go-form').click(function(){
					$('html, body').animate( { scrollTop: $('.agence--form').position().top }, 333 );
				});
			//FORM
				if ( $('.dynamic_value').length > 0) {
					$('.so_cp input').change(function(){
						var val = $(this).val().substr(0,2);
						console.log('trigger with', val);
						$.post(
							ajaxurl,
							{
								'action': 'get_email_by_dep',
								'dep': val,
							},
							function(response){
								//JSON.parse(response.email)
								var data = JSON.parse(response);
								var get_mails = data.email.toString();
								var get_title = data.title.toString();
								var get_tel = data.tel.toString();
								var get_mail_contact = data.mail_contact.toString();
								var get_loc = data.loc.toString().replaceAll(/<br\s*\/?>/gi, " ");

								console.log("mail(s) : ", get_mails);
								console.log("title : ", get_title);
								console.log("tel : ", get_tel);
								console.log("mail_contact : ", get_mail_contact);
								console.log("loc : ", get_loc);

								if (response!=="") {
									$('.so_agence_mail input').val(get_mails);
									$('.set_title input').val(get_title);
									$('.set_tel input').val(get_tel);
									$('.set_mail_contact input').val(get_mail_contact);
									$('.set_loc input').val(get_loc);
								}
							}
						);
					});
				}
        //INIT FOUNDATION
            jQuery(document).foundation();
        });

	},

    checkMobile : function(){
        if($(window).width() < 1280){
            $('body').addClass('mobile').removeClass('desktop');
        } else {
            $('body').addClass('desktop').removeClass('mobile');
        }
				currentDevice = $('body').hasClass('desktop') ? 'desktop' : 'mobile';
    },

    onResize : function() {
        site.checkMobile();
    },

		hp : {
				init : function() {
					site.hp.onResizeHPSlider();
					$(window).resize ( site.hp.onResizeHPSlider );
				},
				onResizeHPSlider : function() {
					site.hp.defineBackgroundSlider( currentDevice );
				},
				defineBackgroundSlider : function(t) {
					$('.slick-slide > .bg').each(function(){
						$(this).css('background-image','url("'+$(this).attr('data-bg-'+t)+'")');
					});
				}
		},

    slider : {

        init: function(){
			$('.slider--hp').slick({
                dots: false,
                arrows:true,
                infinite:true,
				autoplay: true,
  				autoplaySpeed: 3000
            });

			var slidesMax = Number($('.slider--realisations').attr('data-slidesToShow'));
			$('.slider--realisations').slick({
                dots: false,
                arrows:true,
                infinite:false,
				slidesToShow: slidesMax,
				slidesToScroll: slidesMax,
                responsive: [
                    { breakpoint: 3000, settings: { slidesToShow: slidesMax, slidesToScroll:slidesMax } },
					{ breakpoint: 1099, settings: { slidesToShow: 2, slidesToScroll: 1 } },
                    { breakpoint: 700, settings: { slidesToShow: 1, slidesToScroll: 1 } },
                ]
            });

			$('.slider--listing-column').slick({
				dots: false,
				arrows:true,
				infinite:false,
				slidesToShow: 4,
				slidesToScroll: 1,
				responsive: [
					{ breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 1 } },
					{ breakpoint: 700, settings: { slidesToShow: 1, slidesToScroll: 1 } },
				]
			});

			$('.slider--gallery').slick({
                dots: true,
                arrows:true,
                infinite:true,
            });

			$('.slider--testimonials').slick({
				arrows:true,
				infinite:true,
				slidesToShow: 1,
				slidesToScroll: 1
			});

			//GALLERY
			$('.button-gallery-close').click(function(){
				site.gallery.hide();
			});
			$('.button-gallery-open').click(function(){
				site.gallery.show();
			});
        },
    },

	gallery : {
		show : function(){
			$('.gallery--realisation').addClass('visible');
			$('body').addClass('gallery-running');
			$('.slider--gallery').slick('setPosition');
		},
		hide : function(){
			$('.gallery--realisation').removeClass('visible');
			$('body').removeClass('gallery-running');
		}
	},

    animate : {
        init : function(a) {
            autoreverse = a || false;
            //MAIN ANIMATE
                $('*[class*=animate--slide]').each(function() {
                    var str     = $(this).html(),
                        regex   = /<br>/gi;
                    $(this).html('<span>'+str.replace(regex, "</span><span>")+'</span>');
                });
                //trigger
                    elToAnimate = $('.animate');
                    setTimeout(function(){
                        $window.on('scroll resize', site.animate.update);
                        $window.trigger('scroll');
                    }, 500);
        },

        update : function() {
            var windowHeight            = $window.height(),
                windowTopPosition       = $window.scrollTop(),
                windowBottomPosition    = (windowTopPosition + windowHeight),
                activeDelay             = 0.1;

            $.each(elToAnimate, function() {
                var el                  = $(this),
                    elHeight            = el.outerHeight(),
                    elTopPosition       = el.offset().top,
                    elBottomPosition    = (elTopPosition + elHeight);
                    //if ((elBottomPosition >= windowTopPosition) &&
                if ((elTopPosition <= windowBottomPosition-windowHeight*activeDelay)) el.addClass('animate--reveal');
                else if (autoreverse) el.removeClass('animate--reveal');

            });
        }
    },


    map : {

        init : function() {
            var map = site.map.new_map( $('.acf-map') );
			$('.acf-map').addClass('ready');
        },

        new_map : function ( $el ) {

            var $markers 	= $el.find('.marker'),
				lat 		= $el.attr('data-lat') || 0,
				lng 		= $el.attr('data-lng') || 0,
				near_lat	= $el.attr('data-near-lat') || 0,
				near_lng	= $el.attr('data-near-lng') || 0,
            	args = {
                // center		: new google.maps.LatLng(lat, lng),
                mapTypeId	: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                scrollwheel: false,
                // styles: [{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#d3d3d3"}]},{"featureType":"transit","stylers":[{"color":"#808080"},{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"on"},{"color":"#b3b3b3"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"weight":1.8}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"color":"#d7d7d7"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ebebeb"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"color":"#a7a7a7"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#efefef"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#696969"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"color":"#737373"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#d6d6d6"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#dadada"}]}]
            };
            // create map
            var map = new google.maps.Map( $el[0], args);
                map.markers = [];
			// add events
				map.addListener('drag', function() {
				    site.map.hide_infos();
				});
            // add markers
                $markers.each(function(){
                    site.map.add_marker( $(this), map );
                });
            // center map
                site.map.center_map( map, lat, lng, near_lat, near_lng );
            // return
                return map;

        },

        add_marker : function ( $marker, map ) {

            var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
            // create marker
                var marker = new google.maps.Marker({
                    position	: latlng,
                    map			: map,
                    icon        : $marker.attr('data-icon')
                });
            // add to array
                map.markers.push( marker );
            // if marker contains HTML, add it to an infoWindow
                if( $marker.html() ) {
                    // create info window
                    var infowindow = new google.maps.InfoWindow({
                        content		: $marker.html()
                    });
                    // show info window when marker is clicked
                    google.maps.event.addListener(marker, 'click', function() {
						site.map.show_infos( $marker.html() );
                    });
                }
			//trigger resize window to keep center of the map
				google.maps.event.addDomListener(window, 'resize', function() {
					map.setCenter(currentMapCenter);
				});
        },

		show_infos : function (content) {
			$('#data-agences').html ( content ).addClass('visible');
		},

		hide_infos : function (content) {
			$('#data-agences').removeClass('visible');
		},

        center_map : function ( map, lat, lng, near_lat, near_lng ) {

            var bounds = new google.maps.LatLngBounds();
            //if not search
                if( lat == 0 ) {
	                $.each( map.markers, function( i, marker ){
	                    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
	                    bounds.extend( latlng );
	                });
                } else {
                    bounds.extend( new google.maps.LatLng( lat, lng ) );
                    bounds.extend( new google.maps.LatLng( near_lat, near_lng ) );
                }
			//how to center
				if ( map.markers.length == 1 ) {
					map.setCenter( bounds.getCenter() );
					map.setZoom( 16 );
				} else {
				    // if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
					var offset = 0.02;
					var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + offset, bounds.getNorthEast().lng() + offset);
					var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - offset, bounds.getNorthEast().lng() - offset);
					bounds.extend(extendPoint1);
					bounds.extend(extendPoint2);
				    // }
					map.fitBounds( bounds );
				}
			//set map center
				setTimeout(function(){ currentMapCenter = map.getCenter(); }, 20);
				//debug : trace rectangle
				// var rectangle = new google.maps.Rectangle({
			    //       strokeColor: '#FF0000',
			    //       strokeOpacity: 0.8,
			    //       strokeWeight: 2,
			    //       fillColor: '#FF0000',
			    //       fillOpacity: 0.35,
			    //       map: map,
			    //       bounds: {
				// 		north: bounds['f']['b'],
				// 		south: bounds['f']['f'],
				// 		east: bounds['b']['f'],
				// 		west: bounds['b']['b']
			    //       }
			    //     });

        }

    },


    nav : {

        init : function() {

			$('.menu-item-has-children').click(function(){
				if ( $('body').hasClass('mobile') ) {
					if ( $(this).hasClass('open') ) {
						$(this).removeClass('open');
					} else {
						$('.menu-item-has-children').removeClass('open');
						$(this).addClass('open');
					}
				}
			});
				//mobile
					$('#burger').unbind().click(function(){
						$(this).toggleClass('is-close');
						//close agence
						$('body').toggleClass('menu-is-open');
						$('.box-agence').removeClass('is-open');
					});
				$('.round-label, .agence-form-close').click(function(){
					$('.box-agence').toggleClass('is-open');
					if ( $('.box-agence').hasClass('is-open') ) {
						//set focus on field
							$('#search-agence-val').focus();
							//close menu
								$('#burger').removeClass('is-close');
								$('body').removeClass('menu-is-open');
					}
				});
			//search agence in menu box
				// submit only if field is not empty
				// $('#search-button-agence').click(function(){
				// 	if ( $('#search-agence-val').val() !='' ) $('#search-form-agence').submit();
				// 	else $('#search-agence-val').focus();
				// });


			//
			//search word in site
				// submit only if field is not empty
				$('#search-button').click(function(){
					if ( $('#search-site-val').val() !='' ) $('#search-form').submit();
				});
				//open panel and get focus to field
				$('#search-call').click(function(){
					$('#search-form').addClass('open');
					$('.box-agence').addClass('offset');
					$('#search-site-val').focus();
				});
				//close panel
				$('#search-close').click(function(){
					$('#search-form').removeClass('open');
					$('.box-agence').removeClass('offset');
				});
			//
			//remove feedback message in newsletter foo
				$('.newsletter_form').click(function(){
					$('.newsletter_form .gfield_description').hide();
				});
			//
			//handler filter buttons (click for mobile)
				$('.filter').on( 'click mouseenter', function(){
					var clicked = $(this);
					$('.filter').each(function(){
						if ( $(this).html()==clicked.html() ) {
							if ( $(this).hasClass('is-open')) $(this).removeClass('is-open');
							else $(this).addClass('is-open');
						} else $(this).removeClass('is-open');
					});
				}).on( 'mouseleave', function(){
					$('.filter').removeClass('is-open');
				});

			// Close button
			$('.close-button').on('click', function() {
				//hide element
				var $navItem = $(this).closest('.sub-menu');
				$navItem.hide();

				var removeDisplayNone = function() {
					$navItem.css('display', 'flex');
				};

				setTimeout(removeDisplayNone, 50);
			});


			// Not apply on mobile screens
			// to prevent mouseout when hovering over nav item


			$('.nav-main .main-menu .first-level-item').on('mouseenter', function() {
				if($(window).width() > '1100') {
					$(this).css('height', '70px');
				}
			});
			$('.nav-main .main-menu .first-level-item').on('mouseleave', function() {
				if($(window).width() > '1100') {
					$(this).css('height', 'initial');
				}
			});

        }

    },


	actu : {
		init : function() {
			$(window).scroll(function() {
				var scrollTop       = $(window).scrollTop(),
					scrollCurrent   = scrollTop + $(window).height() + 200;
				//load more thumbnails
				if( scrollCurrent >= $(document).height() && !loadingMore ) {
					site.actu.askForMorePost();
				}
			});
			// $('.get-more-news').click(function(){ site.actu.askForMorePost(); });
			// hide more if no more than posts max
			if ( Number($('#posts-list').attr('data-pages-max'))==1 ) $('.get-more-news').hide();

			site.actu.showPosts();

		},
		askForMorePost : function() {
			var paged    = Number($('#posts-list').attr('data-paged')),
				max      = Number($('#posts-list').attr('data-pages-max'));
			if ( paged < max ) {
				loadingMore = true;
				$('.get-more-news').fadeTo(1, 333);
				site.actu.loadMorePosts( paged+1, $('#posts-list').attr('data-category') ); 
			}
		},
		loadMorePosts : function(paged, catID) {
			// console.log('load page ' + paged);
			console.log(arguments);
			$.post(
				ajaxurl,
				{
					'action': 'get_more_posts',
					'paged': paged,
					'category': catID,
				},
				function(response){
					if (response!=="") {
						$('#posts-list').append(response);
						$('#posts-list').attr('data-paged', paged );
						$('#grid--loading').fadeTo(150, 0);
						loadingMore = false;
						site.actu.showPosts();
						//
						if ( $('#posts-list').attr('data-paged') >= $('#posts-list').attr('data-pages-max') ) $('.get-more-news').hide();
					}
				}
			);
		},
		showPosts : function(){
			var i=0;
			$('.actu--item.appear').each(function(){
			// $('.actu--list-item.appear').each(function(){
				$(this).delay(200*i++).fadeTo(333, 1);	//.removeClass('appear');
			});
		}
	},

    realisation : {
        init : function() {
			elFilter		= $('.bloc--filters');
			elFilterPos		= elFilter.offset().top + elFilter.height();

			$(window).scroll(function() {
                var scrollTop       = $(window).scrollTop(),
                    scrollCurrent   = scrollTop + $(window).height(),
					dir 			= scrollPrev < scrollTop ? 'down' : 'up';
                //load more thumbnails
	                if( scrollCurrent+ 200 >= $(document).height() && !loadingMore ) {
	                    site.realisation.askForMoreRealisations();
	                }
				//hide filters
					if ( !isMobile ) {
						if ( scrollTop > elFilterPos - 80 ) {
							if ( !elFilter.hasClass('sticky') ) elFilter.addClass('sticky');
							if ( dir=='up' ) elFilter.addClass('active');
							else elFilter.removeClass('active');

						} else if ( scrollTop < elFilterPos && elFilter.hasClass('sticky') ) {
							elFilter.removeClass('sticky active');
						}
					} else elFilter.removeClass('sticky active');
				//
					scrollPrev		= scrollTop;
            });

			//button more...
            $('.get-more').click(function(){ site.realisation.askForMoreRealisations(); });
            // hide more if no more than posts max
            if ( Number($('#posts-list').attr('data-pages-max'))==1 ) $('.get-more-news').hide();
            // site.realisation.showPosts();
        },
		askForMoreRealisations : function() {
			var el 		= $('#realisations-list'),
				paged	= Number(el.attr('data-paged')),
				max		= Number(el.attr('data-pages-max'));
			if ( paged < max ) {
				loadingMore = true;
				$('.get-more').fadeTo(1, 333);
				site.realisation.loadMoreRealisations( paged+1, el.attr('data-type'), el.attr('data-agence'), el.attr('data-metier') );
			}
		},
        loadMoreRealisations : function(paged, type, agence, metier) {
			console.log('load page ',paged, 'with',type,agence,metier);
			var el = $('#realisations-list');
            $.post(
                ajaxurl,
                {
                    'action': 'get_more_realisations',
                    'paged': paged,
                    'type': type,
                    'agence': agence,
                    'metier': metier,
                },
                function(response){
                    if (response!=="") {
                        el.append(response);
                        el.attr('data-paged', paged );
                        $('#grid--loading').fadeTo(150, 0);
                        loadingMore = false;
                        // site.realisation.showPosts();
                        //
                        if (el.attr('data-paged') >= el.attr('data-pages-max') ) $('.get-more').hide();
                    }
                }
            );
        },
        // showPosts : function(){
        //     var i=0;
        //     $('.actu--item.appear').each(function(){
        //     // $('.actu--list-item.appear').each(function(){
        //         $(this).delay(200*i++).fadeTo(333, 1);	//.removeClass('appear');
        //     });
        // }
    },


		emploi : {
			init : function() {

				if ($('#offres-emploi-list').attr('data-paged') < $('#offres-emploi-list').attr('data-pages-max') ) {
					$('.get-more').css('opacity', '1');
				}

				//button more...
				$('.get-more').click(function(){
					site.emploi.askForMoreJob();
				});
				// hide more if no more than posts max
				if ( Number($('#posts-list').attr('data-pages-max'))==1 ) $('.get-more-news').hide();
				// site.realisation.showPosts();
			},
			askForMoreJob : function() {
				var el 		= $('#offres-emploi-list'),
					paged	= Number(el.attr('data-paged')),
					max		= Number(el.attr('data-pages-max'));
				if ( paged < max ) {
					loadingMore = true;
					$.post(
						ajaxurl,
						{
							'action': 'get_more_job',
							'paged': paged,
						},
						function(response){
							if (response!=="") {
								el.append(response);
								el.attr('data-paged', paged+1);
								$('#grid--loading').fadeTo(150, 0);
								loadingMore = false;
								// site.realisation.showPosts();
								//
								if (el.attr('data-paged') >= el.attr('data-pages-max') ) $('.get-more').hide();
							}
						}
					);
				}
			},
		},

    share : {

        init : function() {

            $("li.share").click(function(e){
                e.preventDefault();
                var id      = $(this).attr('id'),
                    url     = $(this).attr('data-url'),
                    title   = $('meta[property=\'og:title\']').attr('content') || "Soprema",
                    txt     = $('meta[property=\'og:description\']').attr('content') || "Soprema",
                    thumb   = $('meta[property=\'og:image\']').attr('content'),
                    w = 600, h = 450, l = ($(window).width() - w) / 2, t = ($(window).height() - h) / 2, i = i,
                    s = "status=1,width=" + w + ",height=" + h + ",top=" + t + ",left=" + l, v;

                if (id=="facebook") {
                    var u = 'https://www.facebook.com/sharer/sharer.php?s=100&p[url]='+encodeURIComponent(url)+'&p[images][0]='+thumb+'&p[title]='+encodeURIComponent(title)+'&p[summary]='+encodeURIComponent(txt);

                } else if (id=="twitter") {
					var title 	= $('meta[name="twitter:title"]').attr('content') || 'Soprema';
					var txt 	= $('meta[name="twitter:description"]').attr('content') || '';
					var desc 	= title + ' ' + txt;
                    var u   = "https://twitter.com/share?text="+encodeURIComponent(desc)+"&url="+encodeURIComponent(url);

                } else if (id=="linkedin") {
										var u = 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(url);
                    // var u = 'https://www.linkedin.com/cws/share?url='+encodeURIComponent(url);
                } else if (id=="googleplus") {
                    var u = "https://plus.google.com/share?url=" + url;

                } else if (id=="pinterest") {
                    var u = "https://pinterest.com/pin/find/?url="+encodeURIComponent(url);
                } else if (id=="mail") {
                    var u = "mailto:?subject=Soprema&body="+encodeURIComponent(url);
                }
                window.open(u, id, s);
				// console.log(url);
            });
        }

    },



};

function setFocusNl() {
	if (window.location.href.includes('inscription-newsletter')) {
		var input = document.querySelector('.gform_wrapper input[type="text"]');
		if (input !== null) {
			input.focus();
		}
	}
}

$(function() {
	site.init();
	function getMarginLayout() {
		return $('.max-layout').offset().left;
	}

	function setMarginMegaMenu() {
		var menu = document.querySelectorAll('#menu-menumain > .menu-item-has-children > .sub-menu');
		for (var i = 0; i < menu.length; i++) {
			var margin = getMarginLayout();
			menu[i].style.width = 'calc(100% + '+ 2*margin+'px)';
			menu[i].style.left = '-'+margin+'px';
		}
	}

	setMarginMegaMenu();
	setFocusNl();


	window.onresize = function() {
		if (window.innerWidth > 978) {
			setMarginMegaMenu();
		}
	};
});

var selectShow = document.querySelector('.select-show select option:first-child');
var formHide = document.querySelector('.form-to-hide');
if (selectShow) {

	$('.select-show select').change(function(){
		if ($(this).val() !== "Sélectionnez") {
			formHide.style.display = "inline-block";
		}
		else {
			formHide.style.display = "none";
		}
	});
}
